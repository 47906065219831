import { Box, Button, Card, CardContent, Container, Grid, Typography } from "@mui/material";
import msalService from "../services/Authentification/msal-service";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import i18n from "../i18n";
import { useSessionContext } from "../contexts/SessionContext";

export default function LoginPage() {
  const { t } = useTranslation();
  const { organizationId } = useParams<string>();
  const { organization } = useSessionContext();

  const login = () => {
    sessionStorage.setItem("redirect", `/organizations/${organizationId}`);
    msalService.login();
  };

  const disableCreation = organization?.organization.createConsultPage.disableCreation;
  const text = organization?.organization.landingPage.text ? organization.organization.landingPage.text[i18n.language] : undefined;
  const support = organization?.organization.landingPage.support ? organization.organization.landingPage.support[i18n.language] : undefined;

  return (
    <Container sx={{ marginTop: 5 }}>
      <Box minHeight="70vh">
        {organization?.organization && (
          <>
            {!disableCreation && text && (
              <Box textAlign="center" mt={3} mb={5}>
                <Typography variant="body1" fontSize={18}>
                  {parse(text)}
                </Typography>
              </Box>
            )}

            {organization.organization.landingPage.showLogoOnConsultCreation && organization.organization.logoUrl && (
              <Box textAlign="center" mt={3} mb={4}>
                <img src={organization.organization.logoUrl} alt="logo" />
              </Box>
            )}
          </>
        )}

        <Grid container>
          <Grid item xs={0} lg={3}></Grid>

          <Grid item xs={12} lg={6}>
            <Box textAlign="center" my={4}>
              <Typography variant="h2">{parse(t("login-page.title"))}</Typography>
            </Box>
          </Grid>

          <Grid item xs={0} lg={3}></Grid>
        </Grid>

        <Box mt={{ xs: 0, lg: 5 }}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={0} lg={1}></Grid>

            <Grid item xs={12} lg={5}>
              <Card>
                <CardContent>
                  <Typography variant="h5" mb={4}>
                    {parse(t("login-page.patient-title"))}
                  </Typography>

                  <Box>
                    <Typography variant="subtitle1" mb={4}>
                      {parse(t("login-page.patient-text"))}
                    </Typography>

                    <Button size="large" variant="contained" onClick={login}>
                      {t("login-page.button")}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} lg={5}>
              <Card>
                <CardContent>
                  <Typography variant="h5" mb={4}>
                    {parse(t("login-page.practitioner-title"))}
                  </Typography>

                  <Box>
                    <Typography variant="subtitle1" mb={4}>
                      {parse(t("login-page.practitioner-text"))}
                    </Typography>

                    <Button size="large" variant="contained" onClick={login}>
                      {t("login-page.button")}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={0} lg={1}></Grid>
          </Grid>
        </Box>

        {support && (
          <Box textAlign="center" mt={5} mb={5}>
            <Typography variant="body1">{parse(support)}</Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
}

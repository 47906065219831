import Practitioner from "./Practitioner";

export default class PractitionerHelper {
  public static getFullName(practitioner: Practitioner) {
    return `${practitioner.firstName} ${practitioner.lastName}`;
  }

  public static getTitleAndName(practitioner: Practitioner) {
    return `${practitioner.title} ${this.getFullName(practitioner)}`;
  }
}

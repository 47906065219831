import { MenuItem, Select, SelectChangeEvent, Stack, TextField } from "@mui/material";
import i18n from "../../i18n";
import PropsDynamicField from "./PropsDynamicField";
import { useEffect, useState } from "react";
import FieldHelpers from "../../services/Entities/FieldHelpers";

export default function DynamicFieldNumber(props: PropsDynamicField) {
  const { field, data, update } = props;

  const [value, setValue] = useState<number>(0);
  const [valueUnit, setValueUnit] = useState<string>("");
  const [valueUnitError, setValueUnitError] = useState<boolean>(false);

  const fieldidUnit = field.id + "Unit";

  useEffect(() => {
    if (data.hasOwnProperty(field.id)) {
      setValue(data[field.id]);
    }
    if (data.hasOwnProperty(fieldidUnit)) {
      setValueUnit(data[fieldidUnit]);
    }
  }, [props.data]);

  useEffect(() => {
    if (control === "unit") {
      const error = (props.field.required && !data.hasOwnProperty(fieldidUnit)) || data[fieldidUnit]?.length <= 0;
      setValueUnitError(error);
    }
  }, [data]);

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    let numberValue = parseInt(value);
    numberValue = isNaN(numberValue) ? 0 : parseInt(value);

    setValue(numberValue);
    update(field.id, value);
  };

  const handleChangeValueUnit = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setValueUnit(value);
    update(fieldidUnit, value);
  };

  const control = field.properties["control"];
  const minWidth = FieldHelpers.getPropertyNumber(field, "minWidth", 150);
  const minWidthUnit = FieldHelpers.getPropertyNumber(field, "minWidthUnit", 150);

  if (control === "unit") {
    return (
      <>
        <Stack direction="row" alignItems="center">
          <TextField value={value} onChange={handleChangeValue} sx={{ width: minWidth, marginRight: 2 }} error={props.validated && props.error} />

          <Select value={valueUnit} onChange={handleChangeValueUnit} sx={{ minWidth: minWidthUnit }} error={props.validated && valueUnitError}>
            <MenuItem value=""></MenuItem>
            {field.units.map((unit) => {
              return (
                <MenuItem key={unit.id} value={unit.id}>
                  {unit.label[i18n.language]}
                </MenuItem>
              );
            })}
          </Select>
        </Stack>
      </>
    );
  }

  return <TextField value={data[field.id]} onChange={(newValue) => update(field.id, newValue.toString())} />;
}

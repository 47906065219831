import { Box, Paper, Skeleton, Typography } from "@mui/material";

interface Props {
  children: React.PropsWithChildren<any>;
  title?: string;
}

export default function DashboardCard({ children, title }: Props) {
  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h3" color="text.secondary" gutterBottom>
        {title}
        {!title && <Skeleton variant="rectangular" width="50%" animation="pulse" />}
      </Typography>

      <Box position="relative" height="100%">
        {children}
      </Box>
    </Paper>
  );
}

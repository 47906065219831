import { Box, Button, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ConsultList from "../components/Consults/ConsultList";
import { useSessionContext } from "../contexts/SessionContext";
import { useNavigate, useParams } from "react-router-dom";
import MeHelpers from "../services/Authentification/MeHelpers";
import LoginPage from "./LoginPage";
import i18n from "../i18n";
import parse from "html-react-parser";

export default function DashboardPage() {
  const { t } = useTranslation();
  const { user, organization } = useSessionContext();
  const { organizationId } = useParams<string>();
  const navigate = useNavigate();
  const isPractitioner = MeHelpers.isPractitionerOrAdmin(user);

  const disableCreation = organization?.organization.createConsultPage.disableCreation;
  const text = organization?.organization.createConsultPage.text ? organization.organization.createConsultPage.text[i18n.language] : undefined;

  return (
    <Container sx={{ marginTop: 5 }}>
      {disableCreation && text && (
        <Box textAlign="center" mt={3} mb={5}>
          <Typography variant="body1" fontSize={18}>
            {parse(text)}
          </Typography>
        </Box>
      )}

      {user ? (
        <Box>
          <Typography variant="h2" mb={3}>
            {t("dashboard.consults")}
          </Typography>

          {!isPractitioner && (
            <Button
              variant="contained"
              onClick={() => {
                navigate(`/organizations/${organizationId}/new-consult`);
              }}
              disabled={disableCreation}
            >
              {t("consult.create")}
            </Button>
          )}

          <Box marginTop={2}>
            <ConsultList />
          </Box>
        </Box>
      ) : (
        <LoginPage />
      )}
    </Container>
  );
}

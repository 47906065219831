import { useTranslation } from "react-i18next";
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent, Skeleton, Stack, TextField, Theme, Typography, useTheme } from "@mui/material";
import useConsults from "../../services/Consults/useConsults";
import FormatHelpers from "../../services/FormatHelper";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef, GridEventListener, GridRenderCellParams } from "@mui/x-data-grid";
import { useSessionContext } from "../../contexts/SessionContext";
import useConsultPlans from "../../services/ConsultPlans/useConsultPlans";
import { ActiveConsultStates, ConsultState, ConsultStates } from "../../services/Consults/ConsultState";
import ConsultStateComponent from "./ConsultStateComponent";
import { useEffect, useState } from "react";
import consultService from "../../services/Consults/ConsultService";
import MeHelpers from "../../services/Authentification/MeHelpers";
import PractitionerHelper from "../../services/Practitioners/PractitionerHelper";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import PaymentIcon from "@mui/icons-material/Payment";
import dateHelper from "../../services/Core/Dates/DateHelper";
import errorHelper from "../../services/Core/ErrorHelper";
import FaxIcon from "@mui/icons-material/Fax";
import { FaxState } from "../../services/Consults/FaxState";
import Consult from "../../services/Consults/Consult";
import ConsultHelpers from "../../services/Consults/ConsultHelpers";

function NoRowsOverlay() {
  const { t } = useTranslation();

  return (
    <Paper sx={{ mt: 3 }}>
      <Stack p={5} height="100%" alignItems="center" justifyContent="center">
        {t("consult-list.no-rows")}
      </Stack>
    </Paper>
  );
}

export default function ConsultList() {
  const { t } = useTranslation();
  const { user } = useSessionContext();
  const navigate = useNavigate();

  const isPractitioner = MeHelpers.isPractitionerOrAdmin(user);
  const isOrgAdmin = MeHelpers.isOrgAdmin(user);

  const [filterStates, setFilterStates] = useState<Array<number>>(isPractitioner ? ActiveConsultStates : []);
  const [filterText, setFilterText] = useState<string>("");

  const { loading: loadingConsults, consults, refresh } = useConsults(filterStates, filterText);
  const { loading: loadingConsultPlans } = useConsultPlans(user?.organization.id);

  if (loadingConsultPlans) {
    return (
      <Box mb={3}>
        <Skeleton variant="rectangular" width="100%" height={80} animation="pulse" />
      </Box>
    );
  }

  const onConsultClick: GridEventListener<"rowClick"> = (params) => {
    navigate(`/organizations/${params.row.organizationId}/consults/${params.row.id}`);
  };

  const onAssignClick = (organizationId: string, consultId: string, practitionerId: string) => {
    consultService
      .assignPractitionerToConsult(organizationId, consultId, practitionerId)
      .then(refresh)
      .catch((e) => errorHelper.handleError(t, e));
  };

  const columns: GridColDef[] = [
    {
      field: "cue",
      headerName: "",
      type: "string",
      width: 50,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      renderCell: (params: GridRenderCellParams<any, string>) => {
        return <ConsultListCues consult={params.row} />;
      },
    },
    {
      field: "createdOn",
      headerName: t("consult.creation-date"),
      type: "dateTime",
      disableColumnMenu: true,
      sortable: false,
      valueFormatter: (value) => {
        return FormatHelpers.formatDateTimeShort(value);
      },
      flex: 1,
    },
    {
      field: "state",
      headerName: t("consult.status"),
      type: "string",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, ConsultState>) => <ConsultStateComponent state={params.value} />,
      flex: 1,
    },
    {
      field: "patient",
      headerName: t("consult.patientName"),
      type: "string",
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (value, row) => {
        const fn = row.data.PatientFirstName;
        const ln = row.data.PatientLastName;

        if (fn && ln) return `${fn} ${ln}`;
        if (fn) return ln;
        return ln ?? "";
      },

      flex: 1,
    },
    {
      field: "assignedToName",
      headerName: t("consult.assignedTo"),
      type: "string",
      disableColumnMenu: true,
      sortable: false,
      flex: 2,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        if (isOrgAdmin && user && ConsultHelpers.isConsultActive(params.row as Consult)) {
          return (
            <Select
              key={params.id}
              value={params.row.assignedTo}
              size="small"
              onChange={(ev: SelectChangeEvent) => onAssignClick(params.row.organizationId, params.row.id, ev.target.value)}
              fullWidth
            >
              {user.practitioners.map((practitioner, i) => {
                return (
                  <MenuItem key={i} selected={params.row.assignedTo === practitioner.id} value={practitioner.id}>
                    {PractitionerHelper.getFullName(practitioner)}
                  </MenuItem>
                );
              })}
            </Select>
          );
        }
        return params.value;
      },
    },
    // {
    //   field: "consultPlanId",
    //   headerName: t("consult.plan"),
    //   type: "string",
    //   disableColumnMenu: true,
    //   sortable: false,
    //   flex: 2,
    //   valueFormatter: (value) => {
    //     const plan = consultPlans.find((p) => p.id === `${user?.organization.id}-${value}`);
    //     return plan ? plan.title[i18n.language] : "";
    //   },
    // },
    {
      field: "expireOn",
      headerName: t("consult-list.expiration"),
      type: "string",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, Date>) => {
        if (params.value && ConsultHelpers.isConsultActive(params.row as Consult)) {
          const timespan = dateHelper.getDifference(new Date(params.value), new Date(Date.now()));
          let hours = timespan.getTotalHours();
          if (hours < 1 && hours > -1) return "0h";

          return `${Math.floor(timespan.getTotalHours())}h`;
        }
        return <></>;
      },
    },
    { field: "shortId", headerName: t("consult.id"), type: "string", disableColumnMenu: true, sortable: false },
    {
      field: "payment",
      headerName: "",
      type: "string",
      disableColumnMenu: true,
      sortable: false,
      width: 50,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        const consult = params.row;
        if (consult.total > 0 && !consult.paymentId) {
          return <PaymentIcon color="error" titleAccess={t("consult.payment-error-missing-payment")} />;
        }
        return <></>;
      },
    },
  ];

  return (
    <Box>
      <Stack direction="row" pb="0.5rem" alignItems="center">
        <Typography mr={1}>{t("consult-list.search")}</Typography>
        <TextField size="small" value={filterText} onChange={(ev: any) => setFilterText(ev.target.value)} />

        {isPractitioner && <SelectConsultStates consultStates={filterStates} setConsultStates={setFilterStates} />}
      </Stack>

      {loadingConsults ? (
        <Skeleton variant="rectangular" width="100%" height={80} animation="pulse" />
      ) : (
        <>
          {consults.length === 0 ? (
            <NoRowsOverlay />
          ) : (
            <DataGrid
              rows={consults}
              columns={columns}
              onRowClick={onConsultClick}
              initialState={{
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              disableRowSelectionOnClick
              pageSizeOptions={[50]}
              sx={{
                // disable cell selection style
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                // pointer cursor on ALL rows
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
                minHeight: 500,
              }}
            />
          )}
        </>
      )}
    </Box>
  );
}

function SelectConsultStates({ consultStates, setConsultStates }: { consultStates: ConsultState[]; setConsultStates: Function }) {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent<typeof ConsultState | ConsultState[]>) => {
    const {
      target: { value },
    } = event;
    setConsultStates(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <div>
      <FormControl sx={{ marginLeft: "1rem" }}>
        <InputLabel id="demo-multiple-name-label">{t("consult.status")}</InputLabel>
        <Select labelId="demo-multiple-name-label" id="demo-multiple-name" multiple value={consultStates} onChange={handleChange} input={<OutlinedInput label="Name" />}>
          {ConsultStates.map((name) => (
            <MenuItem key={name} value={name} style={getStyles(name, consultStates, theme)}>
              {t(`consult.status-${name}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );

  function getStyles(name: number, personName: ConsultState[], theme: Theme) {
    return {
      fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }
}

function ConsultListCues({ consult }: { consult: Consult }) {
  const { t } = useTranslation();
  const [faxColor, setFaxColor] = useState<"success" | "error" | undefined>(undefined);

  useEffect(() => {
    if (consult.faxStatus === FaxState.Success) setFaxColor("success");
    else if (consult.faxStatus === FaxState.Failed) setFaxColor("error");
  }, [consult]);

  return (
    <Stack direction="row" useFlexGap flexWrap="wrap">
      {consult.hasMessageCue && <MarkChatUnreadIcon titleAccess={t("consult.new-message")} />}

      {faxColor && <FaxIcon color={faxColor} />}
    </Stack>
  );
}

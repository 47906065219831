import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Layout from "./layout/Layout";
import ConsentPage from "./pages/ConsentPage";
import NormeGraphique from "./pages/NormeGraphique";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import DashboardPage from "./pages/DashboardPage";
import { useEffect, useRef } from "react";
import authService from "./services/Authentification/AuthService";
import { useSessionContext } from "./contexts/SessionContext";
import ConsultPage from "./pages/Consult/ConsultPage";
import ProfilePage from "./pages/Profile/ProfilePage";
import CreateConsultPage from "./pages/CreateConsult/CreateConsultPage";
import NetworkError from "./pages/NetworkError";
import { AxiosError } from "axios";
import LoadingFullScreen from "./components/LoadingFullScreen";
import Page404 from "./pages/Page404";
import userService from "./services/Authentification/UserService";
import MeHelpers from "./services/Authentification/MeHelpers";
import OrgAdminStatsPage from "./pages/OrgAdminStatsPage";

function App() {
  const { t } = useTranslation();
  const { initialized: sessionInitialized, consentGiven, setReady, error, setError, organization, setOrganization, user, setUser } = useSessionContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const initialized = useRef(false);
  const initializedLogin = useRef(false);

  // useEffect(() => {
  //   let redirect = sessionStorage.getItem("redirect");
  //   if (redirect) {
  //     console.log("app.useEffect() : Redirect", redirect);

  //     sessionStorage.removeItem("redirect");
  //     navigate(redirect);
  //   }
  // }, []);

  useEffect(() => {
    const getOrgIdFromLocation = async () => {
      const route = "/organizations/";

      let orgId = "dermsmart";
      if (pathname.startsWith(route)) {
        let path = pathname.substring(route.length);
        const n = path.indexOf("/");
        if (n > 1) path = path.substring(0, n);
        orgId = path;
        //console.log("path", path);
      }

      return orgId;
    };

    const init = async () => {
      //console.time("app.init()");
      console.log("app.init(INITIALIZED)");

      try {
        const orgId = await getOrgIdFromLocation();

        //Hack since the app.dermago.ca redirect to /organizations/dermsmart and not to dermago
        console.log("referrer url", document.referrer, orgId);
        if (document.referrer === "https://app.dermago.ca/" && orgId == "dermsmart") {
          console.log("need redirect to dermago organization");
          document.location.href = "https://app.dermsmart.ca/organizations/dermago";
        }

        const org = await userService.getMeOrganization(orgId);
        setOrganization(org);
      } catch (e) {
        const axiosErr = e as AxiosError;
        if (axiosErr) authService.setError(axiosErr.response?.status ?? 500);
        console.error("App.init() -> unexpected error", e);
        setReady(true);
      }
    };

    if (!initialized.current) {
      initialized.current = true;
      // console.log(`App.useEffect: DERMSMART_API=${process.env.REACT_APP_DERMSMART_API}`);
      init();
    }
  }, []);

  useEffect(() => {
    const init = async () => {
      //console.time("app.init()");
      console.log("app.init(Org change)", organization);

      try {
        await authService.initialize(async () => {
          await authService.autoLogin();

          const me = authService.getMe();
          setUser(me);

          if (!me) {
            console.log("App: No user logged in.");
          }

          setError(authService.error);

          console.log("app.init() -> ready");
          //console.timeEnd("app.init()");
          setReady(true);
        });
      } catch (e) {
        const axiosErr = e as AxiosError;
        if (axiosErr) authService.setError(axiosErr.response?.status ?? 500);

        console.error("App.init() -> unexpected error", e);
        setReady(true);
      }
    };

    if (sessionInitialized && !initializedLogin.current) {
      initializedLogin.current = true;
      // console.log(`App.useEffect: DERMSMART_API=${process.env.REACT_APP_DERMSMART_API}`);
      init();
    }
  }, [organization]);

  const ready = authService.isReady();
  const isOrgAdmin = MeHelpers.isOrgAdmin(user);

  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{t("app.title")}</title>
        <meta name="description" content={t("app.description")} />
      </Helmet>

      <Layout>
        {(!sessionInitialized || !ready) && <LoadingFullScreen center={true} />}

        <Routes>
          {error ? (
            <Route path="*" element={<NetworkError />}></Route>
          ) : (
            <>
              <Route path="/organizations/:organizationId" element={<DashboardPage />}></Route>

              {consentGiven ? (
                <Route path="/organizations/:organizationId/new-consult" element={<CreateConsultPage />}></Route>
              ) : (
                <Route path="/organizations/:organizationId/new-consult" element={<ConsentPage />}></Route>
              )}

              <Route path="/organizations/:organizationId/consults/:consultId" element={<ConsultPage />}></Route>

              <Route path="/profile" element={<ProfilePage />}></Route>

              {isOrgAdmin && <Route path="/admin/stats" element={<OrgAdminStatsPage />}></Route>}

              <Route path="/norme-graphique" element={<NormeGraphique />}></Route>

              <Route path="*" element={<Page404 />}></Route>
            </>
          )}
        </Routes>
      </Layout>
    </>
  );
}

export default App;
function universalLanguageDetect(arg0: {
  supportedLanguages: string[]; // Whitelist of supported languages, will be used to filter out languages that aren't supported
  fallbackLanguage: string; // Fallback language in case the user's language cannot be resolved
  errorHandler: (error: any) => void;
}) {
  throw new Error("Function not implemented.");
}

import axios from "axios";
import authService from "./Authentification/AuthService";

export default class BaseApiClient {
  apiUrl: string | undefined = process.env.REACT_APP_DERMSMART_API;

  async get<T>(url: string): Promise<T | undefined> {
    const response = await axios.get<T>(url, await this.getHttpConfig()).catch((error) => {
      return this.handleError("GET", url, error);
    });
    return response?.data;
  }

  async post<Tin, Tout>(url: string, data: Tin): Promise<Tout | undefined> {
    const response = await axios.post<Tout>(url, data, await this.getHttpConfig()).catch((error) => {
      return this.handleError("POST", url, error);
    });
    return response?.data;
  }

  async postResponseless<Tin>(url: string, data: Tin) {
    await axios.post(url, data, await this.getHttpConfig());
  }

  async postBodyless<Tout>(url: string): Promise<Tout | undefined> {
    const response = await axios.post<Tout>(url, undefined, await this.getHttpConfig()).catch((error) => {
      return this.handleError("POST", url, error);
    });
    return response?.data;
  }

  protected async postFile<Tout>(url: string, files: FileList): Promise<Tout | undefined> {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }

    const response = await axios.post<Tout>(url, formData, await this.getHttpConfig()).catch((error: any) => {
      return this.handleError("POST", url, error);
    });
    return response?.data;
  }

  async put<Tin>(url: string, data: Tin): Promise<boolean> {
    const response = await axios.put(url, data, await this.getHttpConfig()).catch((error) => {
      return this.handleError("POST", url, error);
    });
    // console.log("put", response);
    return response?.status === 200;
  }

  async patch<Tin>(url: string, data: Tin): Promise<boolean> {
    const response = await axios.patch(url, data, await this.getHttpConfig()).catch((error) => {
      return this.handleError("PATCH", url, error);
    });
    // console.log("patch", response);
    return response?.status === 200;
  }

  async delete(url: string): Promise<boolean> {
    const response = await axios.delete(url, await this.getHttpConfig()).catch((error) => {
      return this.handleError("DELETE", url, error);
    });
    // console.log("DELETE", response);
    return response?.status === 200;
  }

  protected async getHttpConfig() {
    const token = localStorage.getItem("token");
    // const publicKey = authService.publicKey;
    //console.log("getHttpConfig()", token ? "token avail" : "no token", publicKey);

    return {
      headers: {
        Authorization: `Bearer ${token}`,
        // "X-PublicKey": publicKey,
      },
    };
  }

  private handleError(method: string, url: string, error: any, allow404: boolean = true) {
    if (error.response) {
      if (error.response.status === 404 && allow404) {
        return undefined;
      }

      console.error(`${method} ${url} error ${error.response.status} : '${error.response.statusText}'`);
    } else if (error.message) {
      console.error(`${method} ${url} error ${error.message}`);
    } else {
      console.error(`${method} ${url} unkown error`, error);
    }

    throw error;
  }
}

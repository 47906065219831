import BaseApiClient from "../BaseApiClient";
import Consult from "./Consult";
import ChatResponse from "./ChatResponse";
import ChatRequest, { ConsultMessageRequest } from "./ChatRequest";
import ConsultMessage from "./ConsultMessage";
import CreateConsultRequest from "./Dto/CreateConsultRequest";
import logger from "../Logger";
import CreateConsultResponse from "./Dto/CreateConsultResponse";
import UpdateConsultDiagnosisRequest from "./Dto/UpdateConsultDiagnosisRequest";
import ConsultTreatment from "./ConsultTreatment";
import CreateConsultTreatmentRequest from "./Dto/CreateConsultTreatmentRequest";
import ConsultPrescription from "./ConsultPrescription";
import CreateConsultPrescriptionRequest from "./Dto/CreateConsultPrescriptionRequest";
import ConsultExtended from "./ConsultExtended";
import { ConsultMessageType } from "./ConsultMessageType";
import { ConsultState } from "./ConsultState";

class ConsultService extends BaseApiClient {
  async getConsult(organizationId: string, consultId: string): Promise<Consult | undefined> {
    const consult = await this.get<Consult>(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}`);

    if (consult) {
      consult.createdOn = new Date(consult.createdOn);
    }

    return consult;
  }

  async getConsultExtended(organizationId: string, consultId: string): Promise<ConsultExtended | undefined> {
    const consult = await this.get<ConsultExtended>(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/extended`);

    if (consult) {
      consult.createdOn = new Date(consult.createdOn);

      for (let prescription of consult.prescriptions) {
        prescription.createdOn = new Date(prescription.createdOn);
      }
      for (let message of consult.messages) {
        message.createdOn = message.createdOn ? new Date(message.createdOn) : undefined;
      }
    }

    return consult;
  }

  async getConsults(organizationId: string, states: ConsultState[], filterText: string = ""): Promise<Array<Consult>> {
    const consults = await this.get<Array<Consult>>(`${this.apiUrl}/organizations/${organizationId}/consults?states=${states.join(",")}&text=${filterText}`);
    if (!consults) return [];

    for (let consult of consults) {
      consult.createdOn = new Date(consult.createdOn);
    }

    return consults || [];
  }

  async createConsult(organizationId: string, request: CreateConsultRequest): Promise<CreateConsultResponse | undefined> {
    const response = await this.post<CreateConsultRequest, CreateConsultResponse>(`${this.apiUrl}/organizations/${organizationId}/consults`, request);
    if (!response) {
      logger.log(`createConsult(orgId=${organizationId}, consultPlanId=${request.consultPlanId}, paymentMethodId=${request.paymentMethodId}) -> NOK`);
      return undefined;
    }

    logger.log(`createConsult(orgId=${organizationId}, consultPlanId=${request.consultPlanId}, paymentMethodId=${request.paymentMethodId}) -> OK`, response);
    return response;
  }

  async updateConsult(organizationId: string, request: Consult): Promise<boolean> {
    return await this.put<Consult>(`${this.apiUrl}/organizations/${organizationId}/consults/${request.id}`, request);
  }

  async updateConsultDiagnosic(organizationId: string, consultId: string, text: string) {
    var request: UpdateConsultDiagnosisRequest = { diagnosis: text };
    await this.postResponseless<UpdateConsultDiagnosisRequest>(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/diagnosis`, request);
  }

  async startConsult(organizationId: string, consultId: string) {
    await this.postBodyless(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/start`);
  }

  async closeConsult(organizationId: string, consultId: string) {
    await this.postBodyless(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/close`);
  }

  async reopenConsult(organizationId: string, consultId: string) {
    await this.postBodyless(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/reopen`);
  }

  async cancelConsult(organizationId: string, consultId: string) {
    await this.postBodyless(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/cancel`);
  }

  async assignPractitionerToConsult(organizationId: string, consultId: string, practitionerId: string) {
    await this.post(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/assign`, {
      practitionerId: practitionerId,
    });
  }

  async refundConsult(organizationId: string, consultId: string) {
    await this.postBodyless(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/refund`);
  }

  async chat(consult: Consult, message: ConsultMessage | undefined): Promise<ChatResponse | undefined> {
    const request: ChatRequest = {
      message: message?.message,
    };
    const response = await this.post<ChatRequest, ChatResponse>(`${this.apiUrl}/organizations/${consult.organizationId}/consults/${consult.id}/messages/chat`, request);
    if (!response) return undefined;
    return response;
  }

  async createConsultMessage(consult: Consult, request: ConsultMessageRequest): Promise<ChatResponse | undefined> {
    // console.log(request);
    const response = await this.post<ConsultMessageRequest, ChatResponse>(`${this.apiUrl}/organizations/${consult.organizationId}/consults/${consult.id}/messages`, request);
    if (!response) return undefined;
    return response;
  }

  async getConsultMessages(organizationId: string, consultId: string): Promise<Array<ConsultMessage>> {
    const list = await this.get<Array<ConsultMessage>>(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/messages`);

    if (list) {
      for (let message of list) {
        message.createdOn = message.createdOn ? new Date(message.createdOn) : undefined;
      }
    }

    return list || [];
  }

  /* ----------------------------------------------- */
  /* ----------------- Treatments ------------------ */
  /* ----------------------------------------------- */
  async getConsultTreatments(organizationId: string, consultId: string): Promise<Array<ConsultTreatment>> {
    const list = await this.get<Array<ConsultTreatment>>(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/treatments`);

    if (list) {
      for (let treatment of list) {
        treatment.createdOn = new Date(treatment.createdOn);
      }
    }

    return list || [];
  }

  async createConsultTreatment(organizationId: string, consultId: string, text: string): Promise<ConsultTreatment | undefined> {
    var request: CreateConsultTreatmentRequest = { text };
    const response = await this.post<CreateConsultTreatmentRequest, ConsultTreatment>(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/treatments`, request);
    return response;
  }

  async submitConsultTreatment(organizationId: string, consultId: string, treatmentId: string) {
    await this.postBodyless(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/treatments/${treatmentId}/submit`);
  }

  async approveConsultTreatment(organizationId: string, consultId: string, treatmentId: string) {
    await this.postBodyless(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/treatments/${treatmentId}/approve`);
  }

  async cancelConsultTreatment(organizationId: string, consultId: string, treatmentId: string) {
    await this.postBodyless(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/treatments/${treatmentId}/cancel`);
  }

  /* ----------------------------------------------- */
  /* ---------------- PRESCRIPTIONS ---------------- */
  /* ----------------------------------------------- */
  async getConsultPrescriptions(organizationId: string, consultId: string): Promise<Array<ConsultPrescription>> {
    const list = await this.get<Array<ConsultPrescription>>(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/prescriptions`);

    if (list) {
      for (let prescription of list) {
        prescription.createdOn = new Date(prescription.createdOn);
      }
    }

    return list || [];
  }

  async createConsultPrescription(organizationId: string, consultId: string, text: string): Promise<ConsultPrescription | undefined> {
    var request: CreateConsultPrescriptionRequest = { text };
    const response = await this.post<CreateConsultPrescriptionRequest, ConsultPrescription>(
      `${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/prescriptions`,
      request
    );
    return response;
  }

  async updateConsultPrescriptionText(organizationId: string, consultId: string, prescriptionId: string, text: string): Promise<boolean | undefined> {
    var request: CreateConsultPrescriptionRequest = { text };
    return this.patch(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/prescriptions/${prescriptionId}/text`, request);
  }

  async submitConsultPrescription(organizationId: string, consultId: string, prescriptionId: string) {
    await this.postBodyless(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/prescriptions/${prescriptionId}/submit`);
  }

  async approveConsultPrescription(organizationId: string, consultId: string, prescriptionId: string, pharmacyFax?: string, pharmacyId?: string) {
    await this.post(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/prescriptions/${prescriptionId}/approve`, {
      PharmacyFax: pharmacyFax,
      PharmacyId: pharmacyId,
    });
  }

  async cancelConsultPrescription(organizationId: string, consultId: string, prescriptionId: string) {
    await this.postBodyless(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/prescriptions/${prescriptionId}/cancel`);
  }

  async reactivateConsultPrescription(organizationId: string, consultId: string, prescriptionId: string) {
    await this.postBodyless(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/prescriptions/${prescriptionId}/reactivate`);
  }

  async resendConsultPrescriptionFax(organizationId: string, consultId: string, prescriptionId: string, pharmacyFax?: string, pharmacyId?: string) {
    await this.post(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/prescriptions/${prescriptionId}/fax/send`, {
      PharmacyFax: pharmacyFax,
      PharmacyId: pharmacyId,
    });
  }

  async getConsultPrescriptionFaxDetails(organizationId: string, consultId: string, prescriptionId: string, faxId: string): Promise<any | undefined> {
    return await this.get(`${this.apiUrl}/organizations/${organizationId}/consults/${consultId}/prescriptions/${prescriptionId}/fax/${faxId}`);
  }
}

const consultService = new ConsultService();
export default consultService;

import { Box, Button, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import DynamicFieldSet from "../../components/Field/DynamicFieldSet";
import ConsultPlanHelpers from "../../services/ConsultPlans/ConsultPlanHelpers";
import i18n from "../../i18n";
import parse from "html-react-parser";
import { useCreateConsultContext } from "./CreateConsultContext";
import useConsultPlan from "../../services/ConsultPlans/useConsultPlan";

interface Props {
  isEdit?: boolean;
  data?: any;
  documents: string[];
  onContinue: (data: any) => Promise<void>;
}
export default function Questionnaire(props: Props) {
  const { t } = useTranslation();

  const { organization, plan: contextPlan, condition, documents, setQuestionnaireCompleted, consult } = useCreateConsultContext();
  const { consultPlan: plan } = useConsultPlan(organization?.organization.id, contextPlan?.id, condition);

  const [data, setData] = useState<any>({});
  const [fieldsInError, setFieldsInError] = useState<Array<string>>([]);
  const [validated, setValidated] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const fields = plan ? ConsultPlanHelpers.getAllFields(plan) : [];

  useEffect(() => {
    if (plan && plan.questionnaire) {
      //console.log("Questionnaire", data, documents);
      if (props.data) setData(props.data);
      const imageField = fields.find((f) => f.id === "ImageList");
      if (imageField) {
        setData((prevData: any) => {
          let updatedData = { ...prevData };
          updatedData["ImageList"] = documents ? documents.join(",") : "";
          return updatedData;
        });
      }
    }
  }, [plan, documents, props.data]);

  useEffect(() => {
    if (plan && plan.questionnaire) {
      let errors: string[] = [];

      for (const field of fields) {
        if (field.required) {
          if (!data.hasOwnProperty(field.id) || data[field.id]?.length <= 0) {
            errors.push(field.id);
          }
        }
      }
      setFieldsInError(errors);
    }
  }, [data, plan]);

  const updateData = (fieldId: string, value: string | undefined) => {
    const updatedData = { ...data };
    updatedData[fieldId] = value;

    if (plan!.questionnaire) {
      let errors: string[] = [];
      for (const field of fields) {
        if (field.required) {
          if (!updatedData.hasOwnProperty(field.id) || updatedData[field.id]?.length <= 0) {
            errors.push(field.id);
          }
        }
      }
      setFieldsInError(errors);
    }

    setData(updatedData);
  };

  const onContinue = () => {
    // console.log("onContinue", fieldsInError);

    setValidated(true);
    if (fieldsInError.length <= 0) {
      setInProgress(true);

      //Clean conditionnal answers
      //console.log("data before", cloneConsult.data);
      for (const field of fields) {
        if (field.condition) {
          var dataValue = data[field.condition.field];
          if (field.condition.operator === "equal") {
            const visible = dataValue == field.condition.value;
            if (!visible) {
              data[field.id] = undefined;
            }
          }
        }
      }

      props.onContinue(data).finally(() => {
        setInProgress(false);
        setQuestionnaireCompleted(true);
      });
    }
  };

  const title = plan?.title[i18n.language];

  // console.log("Questionnaire", organization, plan);

  if (!organization || !plan) {
    return (
      <>
        <Box mb={3}>
          <Skeleton variant="rectangular" width="100%" height={100} animation="pulse" />
        </Box>
        <Box mb={3}>
          <Skeleton variant="rectangular" width="100%" height={100} animation="pulse" />
        </Box>
        <Box mb={3}>
          <Skeleton variant="rectangular" width="100%" height={100} animation="pulse" />
        </Box>
      </>
    );
  }

  return (
    <>
      <Typography variant="h1" mb={2}>
        {title} {props.isEdit && consult && `: #${consult.shortId}`}
      </Typography>

      {plan && plan.questionnaire && (
        <>
          {plan.questionnaire.sections.map((section, index) => {
            const sectionTitle = section.title[i18n.language];

            return (
              <Grid key={index} container component="form" columnSpacing={{ sm: 4, lg: 2 }}>
                <Grid item lg={12} mb={1}>
                  <Typography mt={index > 0 ? 1 : 0} variant="h3" sx={{ fontWeight: "bold" }}>
                    {parse(sectionTitle)}
                  </Typography>
                </Grid>

                <DynamicFieldSet
                  fields={section.fields}
                  organizationId={organization.organization.id}
                  data={data}
                  update={updateData}
                  validated={validated}
                  fieldsInError={fieldsInError}
                />
              </Grid>
            );
          })}

          <Stack direction="row" spacing={1}>
            <Button variant="contained" onClick={onContinue} disabled={inProgress}>
              {t(props.isEdit ? "common.save" : "common.continue")}
            </Button>
          </Stack>
        </>
      )}

      {/* <hr />
      <pre>DATA: {JSON.stringify(data, null, 2)}</pre>
      <pre>ERRORS: {JSON.stringify(fieldsInError, null, 2)}</pre> */}
      {/* {fields && <pre>FIELDS: {JSON.stringify(fields, null, 2)}</pre>} */}
    </>
  );
}

import { useEffect, useState } from "react";
import { Notify } from "../Notify";
import { useTranslation } from "react-i18next";
import { useSessionContext } from "../../contexts/SessionContext";
import Consult from "./Consult";
import { useParams } from "react-router-dom";
import consultService from "./ConsultService";
import { ConsultState } from "./ConsultState";

export default function useConsults(states: ConsultState[], filterText?: string) {
  const { t } = useTranslation();
  const { user } = useSessionContext();
  const { organizationId } = useParams<string>();

  const [consults, setConsults] = useState<Array<Consult>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getConsults = () => {
    if (user && organizationId) {
      setLoading(true);

      consultService
        .getConsults(organizationId, states, filterText)
        .then((data) => {
          setConsults(data);
          setLoading(false);
        })
        .catch(() => {
          Notify.error(`${t("common.unexpected-error")}`);
        });
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log("useConsults", organizationId, states, filterText);
      getConsults();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [user, states, filterText]);

  return {
    loading,
    consults,
    refresh: getConsults,
  };
}
